@import '/src/style/normalize.css';

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	/* Setting Up Custom Properties ///////////////////////////////////////////////////////// */
	--sans: 'Raleway', sans-serif;
	--dark-color: rgb(8, 12, 9);
	--dark-green: rgb(77, 86, 61);
	--tan: rgb(149, 140, 101);
	--red: rgb(111, 64, 48);
	--light-gray: rgb(171, 173, 167);
	/* abada7 */
	--cream: rgb(252, 248, 233);

	--h1-size: 3.5rem;
	--h2-size: 2.75rem;
	--h3-size: 2rem;
	--body-size: 1rem;

	/* General Styling ///////////////////////////////////////////////////////// */
	font-family: 'Raleway', sans-serif;
	color: var(--dark-color);
	font-size: var(--body-size);
	background-color: white;
	scroll-behavior: smooth;
}

/* Everything bigger than 900px */
@media (min-width: 900px) {
	:root {
		--h1-size: 3.5rem;
		--h2-size: 3rem;
		--h3-size: 2.5rem;
		--body-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-align: center;
	margin: 3rem;
}

h1 {
	margin-top: 75px;
	font-size: var(--h1-size);
	font-weight: 200;
	text-transform: uppercase;
}

h2 {
	font-size: var(--h2-size);
	font-weight: 200;
}

h3 {
	font-size: var(--h3-size);
	font-weight: 200;
}

h4 {
	font-size: var(--h3-size);
	font-weight: 200;
}

@media (min-width: 900px) {

	/* Thinner headings on desktop */
	h1 {
		font-weight: 100;
	}

	h2 {
		font-weight: 100;
	}

	h3 {
		font-weight: 200;
	}

	h4 {
		font-weight: 200;
	}
}

p {
	width: 90%;
	max-width: 400px;
	margin: 0 auto;
	line-height: 1.5rem;
}

blockquote,
q {
	line-height: 1.5rem;
}

@media (min-width: 900px) {
	p {
		max-width: 600px;
	}
}

a {
	color: var(--dark-color);
	text-decoration: underline;
	font-weight: 600;
}

@media (min-width: 900px) {
	a {
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}
}

section {
	overflow: hidden;
}

header {
	z-index: 100;
	background-color: white;
}

nav {
	position: fixed;
	background-color: white;
	z-index: 100;
	display: grid;
	grid-template-rows: repeat(6, 1fr);
	width: 100vw;
	text-align: center;
	left: -100vw;
	top: 0;
	opacity: 0;
	border-radius: 0 0 0 5px;
}

nav a {
	width: fit-content;
	height: fit-content;
	font-weight: 400;
	text-decoration: none;
	position: relative;
	outline: none;
}

body[data-is-keyboard-user="true"] nav a:focus::after {
	position: absolute;
	content: " ";
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% + 1rem);
	height: calc(100% + 1rem);
	border-radius: 5px;
	outline: 2px solid black;
}

@media (min-width: 900px) {
	body[data-is-keyboard-user="true"] nav a:focus::after {
		width: calc(100% + 0.25rem);
	height: calc(100% + 0.25rem);
	}
}

nav div {
	width: fit-content;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
}

nav div p {
	font-size: var(--body-size);
	width: fit-content;
	height: fit-content;
	position: relative;
	margin: 0;
	display: flex;
	place-items: center center;
	text-align: center;
}

@media (max-width: 900px) {
	nav div p {
		font-size: var(--h3-size);
	}
}

.menu-toggle {
	position: fixed;
	top: 10px;
	left: 10px;
	z-index: 101;
	height: 40px;
	width: 40px;
	cursor: pointer;
	background-color: white;
	border: none;
	outline: none;

	opacity: 0;
}

body[data-is-keyboard-user="true"] .menu-toggle:focus::after {
	position: absolute;
	content: " ";
	top: -0.125rem;
	left: -0.125rem;
	width: calc(100% + 0.25rem);
	height: calc(100% + 0.25rem);
	border-radius: 5px;
	outline: 2px solid black;
}

.menu-toggle__line {
	background: var(--dark-color);
	height: 4px;
	position: absolute;
	border-radius: 10px;
}

.menu-toggle__line--1 {
	top: 9px;
	width: 60%;
	left: 30%;
}

.menu-toggle__line--2 {
	width: 80%;
	left: 10%;
	top: 43.5%;
}

.menu-toggle__line--3 {
	top: calc(100% - 13px);
	width: 60%;
	left: 10%;
}

/* Music articles ///////////////////////////////////////// */
.music-article p {
	margin: 1rem auto;
	width: calc(100% - 4rem);
	min-width: 250px;
}

.music-article {
	border: 1px solid var(--dark-color);
	width: 100%;
	margin: 0 auto;
}

.music-article p:last-child {
	margin-bottom: 2rem;
}

.music-article h2,
.music-article h3 {
	margin: 2rem auto;
	text-align: center;
	width: calc(100% - 4rem);
}

.music-article img {
	display: block;
	width: 50%;
	max-width: 600px;
	min-width: 250px;
	margin: 2rem auto;
}

audio {
	display: block;
	margin: 3rem auto 1rem;
	width: 100%;
	max-width: 250px;
}

@media (min-width: 900px) {
	audio {
		max-width: 560px;
	}
}

.music-article>iframe {
	display: block;
	margin: 2rem auto 1rem;
	max-width: 100%;
}

#performers {
	font-style: italic;
	margin: 1rem auto 2rem;
	width: 100%;
	max-width: 250px;
}

@media (min-width: 900px) {
	article #performers {
		max-width: 560px;
	}
}

/* ///////////////////////////////////////////////////////// */

footer {
	margin-top: 100px;
	margin-bottom: 25px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

footer p.copyright {
	position: relative;
	text-align: center;
	width: 100%;
	max-width: 100%;
	margin: 1rem auto 1rem;
}

footer a {
	display: flex;
	margin: 0 auto;
	width: 50px;
	height: 50px;
	justify-content: center;
	align-items: center;
}

footer a .mute {
	display: block;
	width: 2rem;
}

/* HIDING NEWS PAGE FOR NOW /////////////////////////////////////////////////// */
/* DELETE THIS TO SHOW NEWS PAGE/////////////////////////////////////////////// */
a.news {
	display: none;
}

nav {
	grid-template-rows: repeat(5, 1fr);
}