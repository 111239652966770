*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  --sans: "Raleway", sans-serif;
  --dark-color: #080c09;
  --dark-green: #4d563d;
  --tan: #958c65;
  --red: #6f4030;
  --light-gray: #abada7;
  --cream: #fcf8e9;
  --h1-size: 3.5rem;
  --h2-size: 2.75rem;
  --h3-size: 2rem;
  --body-size: 1rem;
  color: var(--dark-color);
  font-family: Raleway, sans-serif;
  font-size: var(--body-size);
  scroll-behavior: smooth;
  background-color: #fff;
}

@media (min-width: 900px) {
  :root {
    --h1-size: 3.5rem;
    --h2-size: 3rem;
    --h3-size: 2.5rem;
    --body-size: 1.125rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  margin: 3rem;
}

h1 {
  font-size: var(--h1-size);
  text-transform: uppercase;
  margin-top: 75px;
  font-weight: 200;
}

h2 {
  font-size: var(--h2-size);
  font-weight: 200;
}

h3, h4 {
  font-size: var(--h3-size);
  font-weight: 200;
}

@media (min-width: 900px) {
  h1, h2 {
    font-weight: 100;
  }

  h3, h4 {
    font-weight: 200;
  }
}

p {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  line-height: 1.5rem;
}

blockquote, q {
  line-height: 1.5rem;
}

@media (min-width: 900px) {
  p {
    max-width: 600px;
  }
}

a {
  color: var(--dark-color);
  font-weight: 600;
  text-decoration: underline;
}

@media (min-width: 900px) {
  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

section {
  overflow: hidden;
}

header {
  z-index: 100;
  background-color: #fff;
}

nav {
  z-index: 100;
  width: 100vw;
  text-align: center;
  opacity: 0;
  background-color: #fff;
  border-radius: 0 0 0 5px;
  grid-template-rows: repeat(6, 1fr);
  display: grid;
  position: fixed;
  top: 0;
  left: -100vw;
}

nav a {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  outline: none;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}

body[data-is-keyboard-user="true"] nav a:focus:after {
  content: " ";
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  border-radius: 5px;
  outline: 2px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 900px) {
  body[data-is-keyboard-user="true"] nav a:focus:after {
    width: calc(100% + .25rem);
    height: calc(100% + .25rem);
  }
}

nav div {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
}

nav div p {
  font-size: var(--body-size);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  place-items: center;
  margin: 0;
  display: flex;
  position: relative;
}

@media (max-width: 900px) {
  nav div p {
    font-size: var(--h3-size);
  }
}

.menu-toggle {
  z-index: 101;
  height: 40px;
  width: 40px;
  cursor: pointer;
  opacity: 0;
  background-color: #fff;
  border: none;
  outline: none;
  position: fixed;
  top: 10px;
  left: 10px;
}

body[data-is-keyboard-user="true"] .menu-toggle:focus:after {
  content: " ";
  width: calc(100% + .25rem);
  height: calc(100% + .25rem);
  border-radius: 5px;
  outline: 2px solid #000;
  position: absolute;
  top: -.125rem;
  left: -.125rem;
}

.menu-toggle__line {
  background: var(--dark-color);
  height: 4px;
  border-radius: 10px;
  position: absolute;
}

.menu-toggle__line--1 {
  width: 60%;
  top: 9px;
  left: 30%;
}

.menu-toggle__line--2 {
  width: 80%;
  top: 43.5%;
  left: 10%;
}

.menu-toggle__line--3 {
  width: 60%;
  top: calc(100% - 13px);
  left: 10%;
}

.music-article p {
  width: calc(100% - 4rem);
  min-width: 250px;
  margin: 1rem auto;
}

.music-article {
  border: 1px solid var(--dark-color);
  width: 100%;
  margin: 0 auto;
}

.music-article p:last-child {
  margin-bottom: 2rem;
}

.music-article h2, .music-article h3 {
  text-align: center;
  width: calc(100% - 4rem);
  margin: 2rem auto;
}

.music-article img {
  width: 50%;
  max-width: 600px;
  min-width: 250px;
  margin: 2rem auto;
  display: block;
}

audio {
  width: 100%;
  max-width: 250px;
  margin: 3rem auto 1rem;
  display: block;
}

@media (min-width: 900px) {
  audio {
    max-width: 560px;
  }
}

.music-article > iframe {
  max-width: 100%;
  margin: 2rem auto 1rem;
  display: block;
}

#performers {
  width: 100%;
  max-width: 250px;
  margin: 1rem auto 2rem;
  font-style: italic;
}

@media (min-width: 900px) {
  article #performers {
    max-width: 560px;
  }
}

footer {
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 100px;
  margin-bottom: 25px;
  display: flex;
}

footer p.copyright {
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 1rem auto;
  position: relative;
}

footer a {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

footer a .mute {
  width: 2rem;
  display: block;
}

a.news {
  display: none;
}

nav {
  grid-template-rows: repeat(5, 1fr);
}

header, nav {
  background-color: #fff;
}

nav .home {
  font-weight: 600;
}

.menu-toggle {
  background-color: var(--cream);
}

@media (min-width: 900px) {
  header, nav {
    background-color: var(--cream);
  }
}

.slanted-div span {
  display: block;
}

.title--yevgeniy {
  text-align: center;
  margin-top: 75px;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  line-height: 1.25;
}

.title--composer {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.title--yevgeniy img {
  height: 4.25rem;
  opacity: 1;
  margin: 0 1rem;
  display: inline-block;
  position: relative;
}

.title--yevgeniy .yevgeniy-img {
  height: 3.5rem;
}

.title--yevgeniy .sharlat-img {
  top: calc(var(--h1-size) * .15);
}

@media (max-width: 650px) {
  .title--yevgeniy img {
    display: none;
  }
}

.pronunciation {
  max-width: 90%;
  width: max-content;
  color: #555;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 1.5rem auto 70px;
  padding: .5rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  position: relative;
  left: .5rem;
}

body[data-is-keyboard-user="true"] .pronunciation:focus:after {
  content: " ";
  width: calc(100% + .25rem);
  height: calc(100% + .25rem);
  border-radius: 5px;
  outline: 2px solid #000;
  position: absolute;
  top: -.125rem;
  left: -.125rem;
}

.pronunciation-audio {
  display: none;
}

.pronunciation img {
  height: 1.5rem;
  margin-right: .5rem;
  display: inline-block;
  position: relative;
  top: .2rem;
}

.pronunciation:active {
  background-color: #00000026;
  border-radius: 2rem;
}

.slanted-div {
  z-index: -100;
  background-color: var(--cream);
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  top: 0;
  overflow: hidden;
}

@media (min-width: 900px) {
  .slanted-div {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
  }
}

.news-item {
  width: 90%;
  min-width: 300px;
  max-width: 700px;
  border: 1px solid gray;
  margin: 3rem auto;
}

.news-item time {
  margin-top: 3rem;
  font-style: italic;
  display: block;
}

.news-item p, .news-item p:last-child, .news-item time {
  width: 90%;
  min-width: 250px;
  max-width: 600px;
  margin: 1rem auto;
}

.featured-pieces-section {
  margin-top: 2rem;
  margin-bottom: 100px;
  overflow: visible;
}

.featured-pieces {
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: dense;
  grid-gap: 10px;
  opacity: 0;
  justify-content: center;
  margin: 0 auto;
  display: grid;
  position: relative;
  top: -5rem;
}

.featured-pieces__item--outer {
  width: 150px;
  height: 150px;
  cursor: pointer;
  background-color: #e6e6e6;
  outline: none;
  position: relative;
  overflow: hidden;
}

body[data-is-keyboard-user="true"] .featured-pieces__item--outer:focus {
  outline: 2px solid #000;
}

.overlay {
  width: 120%;
  height: 120%;
  opacity: 0;
  z-index: 9;
  background-color: #7d7d7d;
  position: absolute;
  top: -10%;
  left: -10%;
}

.featured-pieces__item--outer > img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 8;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.featured-pieces__item--inner {
  height: 100%;
  width: 100%;
  opacity: .8;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 900px) {
  .featured-pieces__item--inner {
    opacity: 0;
  }
}

.featured-pieces__item--inner h3, .featured-pieces__item--inner h4 {
  text-align: left;
  width: 100%;
  font-size: var(--body-size);
  color: #0000;
  width: 100%;
  margin: 0;
  padding: .5rem .5rem 0;
  position: relative;
}

.big {
  height: 310px;
  width: 310px;
  grid-area: auto / auto / span 2 / span 2;
}

.big h3, .big h3, .big h3 {
  font-size: var(--h3-size);
}

.window-to-open {
  z-index: 103;
  width: calc(100vw - 50px);
  height: calc(100vh - 75px);
  max-width: 1200px;
  background-color: #fff;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.window-to-open article {
  width: 100%;
  height: 100%;
  border: none;
}

.window-to-open article h2 {
  margin: 5rem auto 0;
}

.close-window-button {
  z-index: 104;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #0000;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  top: 1rem;
  left: 1rem;
}

@media (hover: hover) {
  body[data-is-keyboard-user="true"] .close-window-button:focus:after {
    content: " ";
    width: calc(100% + .25rem);
    height: calc(100% + .25rem);
    border-radius: 5px;
    outline: 2px solid #000;
    position: absolute;
    top: -.125rem;
    left: -.125rem;
  }
}

article p {
  max-width: 800px;
  width: 90%;
  margin: 1rem auto;
}

article p:last-child {
  margin-bottom: 3rem;
}

@media (min-width: 900px) {
  .title--yevgeniy {
    font-size: 2.5rem;
    font-weight: 200;
    line-height: 1.25;
    position: relative;
  }

  .title--composer {
    font-size: 1.75rem;
    font-weight: 100;
    position: relative;
  }

  .pronunciation {
    margin: 1.25rem auto 5rem;
    font-size: 1.5rem;
    font-weight: 300;
    position: relative;
  }

  .title--featured-pieces {
    margin: 3rem auto;
  }
}

.dark-background {
  height: 100vh;
  width: 100vw;
  z-index: -10;
  opacity: 0;
  cursor: pointer;
  background: #000000bf;
  position: fixed;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.fa977906.css.map */
