@import '/src/style/common.css';

header,
nav {
	background-color: white;
}

nav .home {
	font-weight: 600;
}

.menu-toggle {
	background-color: var(--cream);
}

@media (min-width: 900px) {

	header,
	nav {
		background-color: var(--cream);
	}
}

.slanted-div span {
	display: block;
}

.title--yevgeniy {
	font-size: 2.5rem;
	margin-top: 75px;
	margin-bottom: 2rem;
	text-align: center;
	line-height: 1.25;
}

.title--composer {
	font-size: 1.5rem;
	margin-top: 2rem;
	margin-bottom: 1.5rem;
}

.title--yevgeniy img {
	display: inline-block;
	height: 4.25rem;
	position: relative;
	margin: 0 1rem;
	opacity: 1;
}

.title--yevgeniy .yevgeniy-img {
	height: 3.5rem;
}

.title--yevgeniy .sharlat-img {
	top: calc(var(--h1-size) * 0.15);
}

@media (max-width: 650px) {
	.title--yevgeniy img {
		display: none;
	}
}

.pronunciation {
	position: relative;
	display: block;
	max-width: 90%;
	width: max-content;
	margin: 1.5rem auto 70px;
	font-size: 1.25rem;
	font-weight: 400;
	color: #555;
	cursor: pointer;
	text-align: center;
	line-height: 1.25;
	padding: 0.5rem;
	left: 0.5rem;
	border: none;
	outline: none;
	background-color: transparent;
}

body[data-is-keyboard-user="true"] .pronunciation:focus::after {
	position: absolute;
	content: " ";
	top: -0.125rem;
	left: -0.125rem;
	width: calc(100% + 0.25rem);
	height: calc(100% + 0.25rem);
	border-radius: 5px;
	outline: 2px solid black;
}

.pronunciation-audio {
	display: none;
}

.pronunciation img {
	display: inline-block;
	position: relative;
	height: 1.5rem;
	top: 0.2rem;
	margin-right: 0.5rem;
}

.pronunciation:active {
	background-color: rgba(0, 0, 0, 0.15);
	border-radius: 2rem;
}

.slanted-div {
	overflow: hidden;
	top: 0;
	z-index: -100;
	background-color: var(--cream);
	width: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}

@media (min-width: 900px) {
	.slanted-div {
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
	}
}

/* News Item /////////////////////////////////////////////// */

.news-item {
	width: 90%;
	min-width: 300px;
	max-width: 700px;
	border: 1px solid gray;
	margin: 3rem auto;
}

.news-item time {
	margin-top: 3rem;
	display: block;
	font-style: italic;
}

.news-item p,
.news-item p:last-child,
.news-item time {
	margin: 1rem auto;
	width: 90%;
	min-width: 250px;
	max-width: 600px;
}

/* Featured Pieces /////////////////////////////////////////////// */

.featured-pieces-section {
	margin-bottom: 100px;
	overflow: visible;
	margin-top: 2rem;
}

.featured-pieces {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	grid-auto-flow: dense;
	margin: 0 auto;
	grid-gap: 10px;
	justify-content: center;
	top: -5rem;
	/* revealed on load */
	opacity: 0;
}

.featured-pieces__item--outer {
	position: relative;
	width: 150px;
	height: 150px;
	cursor: pointer;
	overflow: hidden;
	/* This is the background color of boxes before an image is added */
	background-color: rgb(230, 230, 230);
	outline: none;
}

body[data-is-keyboard-user="true"] .featured-pieces__item--outer:focus {
	outline: 2px solid black;
}

.overlay {
	position: absolute;
	width: 120%;
	height: 120%;
	left: -10%;
	top: -10%;
	opacity: 0;
	z-index: 9;
	/* This is the color that changes when you hover over an item */
	background-color: rgb(125, 125, 125);
}

/* Paintings */
.featured-pieces__item--outer>img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	z-index: 8;
	border: none;
}

.featured-pieces__item--inner {
	top: 0;
	left: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 0.8;
	z-index: 10;
}

@media (min-width: 900px) {
	.featured-pieces__item--inner {
		opacity: 0;
	}
}

.featured-pieces__item--inner h3,
.featured-pieces__item--inner h4 {
	position: relative;
	text-align: left;
	width: 100%;
	margin: 0.25rem;
	font-size: var(--body-size);
	color: transparent;
	width: 100%;
	margin: 0;
	padding: 0.5rem;
	padding-bottom: 0;
}

.big {
	height: 310px;
	width: 310px;
	grid-row: auto / span 2;
	grid-column: auto / span 2;
}

.big h3,
.big h3,
.big h3 {
	font-size: var(--h3-size);
}

.window-to-open {
	display: none;
	position: fixed;
	z-index: 103;
	width: calc(100vw - 50px);
	height: calc(100vh - 75px);
	max-width: 1200px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: auto;
	background-color: white;
}

.window-to-open article {
	width: 100%;
	height: 100%;
	border: none;
}

.window-to-open article h2 {
	margin: 5rem auto 0;
}

.close-window-button {
	background-color: transparent;
	z-index: 104;
	border: none;
	outline: none;
	position: fixed;
	top: 1rem;
	left: 1rem;
	cursor: pointer;
	padding: 0;
	margin: 0;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (hover: hover) {
	body[data-is-keyboard-user="true"] .close-window-button:focus:after {
		position: absolute;
		content: " ";
		top: -0.125rem;
		left: -0.125rem;
		width: calc(100% + 0.25rem);
		height: calc(100% + 0.25rem);
		border-radius: 5px;
		outline: 2px solid black;
	}
}

article p {
	max-width: 800px;
	width: 90%;
	margin: 1rem auto;
}

article p:last-child {
	margin-bottom: 3rem;
}

@media (min-width: 900px) {
	.title--yevgeniy {
		position: relative;
		font-size: 2.5rem;
		font-weight: 200;
		line-height: 1.25;
	}

	.title--composer {
		font-size: 1.75rem;
		position: relative;
		font-weight: 100;
	}

	.pronunciation {
		font-size: 1.5rem;
		position: relative;
		margin: 1.25rem auto 5rem;
		font-weight: 300;
	}

	.title--featured-pieces {
		margin: 3rem auto;
	}
}

.dark-background {
	background: rgba(0, 0, 0, 0.75);
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;

	z-index: -10;
	opacity: 0;
	cursor: pointer;
}